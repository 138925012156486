<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="p-0">
          <!-- <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="124px"
            rounded
          />
           -->
          <b-img-lazy
            src="https://picsum.photos/200/300?grayscale"
            width="104"
            height="134"
            rounded
          />
        </div>
        <div class="pl-2">
          <h4 class="">회원명</h4>
          <span class="card-text">
            <p>14기</p>
            <p>직장명 / 직함</p>
            <p>010-0000-0000</p>
          </span>
        </div>

        <!-- User Stats -->
        <div class="ml-auto align-middle">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="lg"
              class="btn-icon"
              href="tel:01050010982"
            >
              <feather-icon icon="PhoneCallIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="lg"
              class="btn-icon ml-1"
              href="mailto:aaa@aaa.com"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </div>
          <div class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="lg"
              class="btn-icon"
              href="sms:01050010982"
            >
              <feather-icon icon="SendIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.collapse-info
              variant="primary"
              size="lg"
              class="btn-icon ml-1"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-collapse id="collapse-info">
        <b-col cols="12">
          <table class="mt-1 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">이메일</span>
              </th>
              <td class="pb-50">aaaa@aaaa.com</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">업종</span>
              </th>
              <td class="pb-50 text-capitalize">기카</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">직장전화</span>
              </th>
              <td class="pb-50 text-capitalize">02-0000-0000</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">주소</span>
              </th>
              <td class="pb-50">(80990) 서울시 서울구 서울로 170 2층</td>
            </tr>
          </table>
        </b-col>
      </b-collapse>
    </b-row>
  </b-card>
</template>

<script>
  import {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCollapse,
    VBToggle,
    BImgLazy,
    BImg,
  } from 'bootstrap-vue';
  import { avatarText } from '@core/utils/filter';
  // import useUsersList from '../users-list/useUsersList';
  import Ripple from 'vue-ripple-directive';

  export default {
    components: {
      BCard,
      BButton,
      BRow,
      BCol,
      BAvatar,
      BCollapse,
      BImgLazy,
      BImg,
    },
    props: {
      userData: {
        type: Object,
        required: true,
      },
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple,
    },
    setup() {
      // const { resolveUserRoleVariant } = useUsersList();
      return {
        avatarText,
        resolveUserRoleVariant,
      };
    },
  };
</script>

<style></style>
